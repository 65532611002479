// Footer.js
import React from 'react';
import './styles/Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <p>&copy; {new Date().getFullYear()} Krasunia_zp. Всі права захищені.</p>
        </footer>
    );
};

export default Footer;
