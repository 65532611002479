// Team.js
import React from 'react';
import './styles/Team.css';

const teamData = [
    {
        name: 'Анна М.',
        position: 'Косметолог',
        image: 'path/to/team1.jpg',
    },
    {
        name: 'Світлана В.',
        position: 'Спеціаліст з лазерної епіляції',
        image: 'path/to/team2.jpg',
    },
    {
        name: 'Олена Д.',
        position: 'Масажист',
        image: 'path/to/team3.jpg',
    },
];

const Team = () => {
    return (
        <section className="team">
            <h2>Наша Команда</h2>
            <div className="team-list">
                {teamData.map((member, index) => (
                    <div className="team-card" key={index}>
                        <img src={member.image} alt={member.name} />
                        <h3>{member.name}</h3>
                        <p>{member.position}</p>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Team;
