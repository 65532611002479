// Navbar.js
import React from 'react';
import './styles/Navbar.css';
import { Link } from 'react-router-dom';

const Navbar = () => {
    return (
        <nav className="navbar">
            <div className="logo">
                <Link to="/">krasunia_zp</Link>
            </div>
            <div className="nav-links">
                <Link to="/about">Про нас</Link>
                <Link to="/services">Послуги</Link>
                <Link to="/contact">Контакти</Link>
            </div>
        </nav>
    );
};

export default Navbar;
