// ContactForm.js
import React, { useState } from 'react';
import './styles/ContactForm.css';

const ContactForm = () => {
    const [formData, setFormData] = useState({ name: '', email: '', message: '' });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Здесь можно добавить логику отправки формы на сервер или email
        alert('Дякуємо за ваше повідомлення!');
        setFormData({ name: '', email: '', message: '' });
    };

    return (
        <section className="contact-form">
            <h2>Зворотній зв'язок</h2>
            <form onSubmit={handleSubmit}>
                <input type="text" name="name" placeholder="Ваше ім'я" value={formData.name} onChange={handleChange} required />
                <input type="email" name="email" placeholder="Ваш email" value={formData.email} onChange={handleChange} required />
                <textarea name="message" placeholder="Ваше повідомлення" value={formData.message} onChange={handleChange} required />
                <button type="submit">Надіслати</button>
            </form>
        </section>
    );
};

export default ContactForm;
