import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import MainPage from './pages/mainPage';
import AboutUs from "./components/AboutUs/AboutUs";
import ContactForm from "./components/ContactForm/ContactForm";
import Services from "./components/Services/Services";

function App() {
    return (
        <Router>
            <Navbar />
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/contact" element={<ContactForm />} />
                {/* Параметр `isHomePage={true}` для главной страницы */}
                <Route path="/" element={<Services isHomePage={true} />} />
                {/* Параметр `isHomePage={false}` для страницы услуг */}
                <Route path="/services" element={<Services isHomePage={false} />} />
            </Routes>
        </Router>
    );
}

export default App;
