import React, { useEffect, useState } from 'react';
import "../../cards/styles/ProductCard.css";
import "./styles/Categories.css";

const Categories = () => {
    return (
        <div className="categories-container">
            <a href="/laser-dep" className="no-style-link category-link">
                <h2 className="h2">Лазерна Єпіляція</h2>
            </a>
            <a href="/laser_young" className="no-style-link category-link">
                <h2>Лазерне омолодження</h2>
            </a>
            <a href="/anticell_massage" className="no-style-link category-link">
                <h2>Антицеллюлітний массаж</h2>
            </a>
            <a href="/electoepil" className="no-style-link category-link">
                <h2>Електроєпіляція</h2>
            </a>
        </div>
    );
};
export default Categories;
