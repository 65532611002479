// HeroSection.js
// 123
import React from 'react';
import './styles/HeroSection.css';
import image333 from '../../images/HeroSection/image333-1.jpg';  // Правильный путь на уровень выше



const HeroSection = () => {
    return (
        <div className="hero-section">
            <div className="hero-content">

                <h1>Професійний догляд за вашим тілом та обличчям</h1>
                <p>Зробіть крок до ідеальної шкіри разом з нами!</p>
                <a href="https://bumpix.net/uk/218117" target="_blank" rel="noopener noreferrer" className="cta-button">
                    Записатися зараз
                </a>
            </div>
        </div>
    );
};

export default HeroSection;
