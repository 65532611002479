// Testimonials.js
import React from 'react';
import './styles/Testimonials.css';

const testimonialsData = [
    {
        name: 'Ольга П.',
        feedback: 'Дуже задоволена результатом! Рекомендую всім!',
        image: 'path/to/client1.jpg',
    },
    {
        name: 'Ірина К.',
        feedback: 'Професійний підхід та приємна атмосфера.',
        image: 'path/to/client2.jpg',
    },
    {
        name: 'Марина С.',
        feedback: 'Краща студія в місті! Буду приходити ще.',
        image: 'path/to/client3.jpg',
    },
];

const Testimonials = () => {
    return (
        <section className="testimonials">
            <h2>Відгуки наших клієнтів</h2>
            <div className="testimonials-list">
                {testimonialsData.map((testimonial, index) => (
                    <div className="testimonial-card" key={index}>
                        <img src={testimonial.image} alt={testimonial.name} />
                        <p>"{testimonial.feedback}"</p>
                        <h4>{testimonial.name}</h4>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Testimonials;
