import React, { useEffect, useState } from 'react';
import "../../cards/styles/ProductCard.css";
import "./styles/Services.css";

const Services = ({ isHomePage = false }) => {
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch('/api/services')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setServices(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error.message);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <p>Загрузка...</p>;
    }

    if (error) {
        return <p>Ошибка: {error}</p>;
    }

    const displayedServices = isHomePage ? services.slice(0, 6) : services;

    return (
        <div className="services-section">
            <h1 className="h1">Наші Послуги</h1>
            <div className="services-container">
                {displayedServices.map(service => (
                    <div key={service._id} className="service-card">
                        <img src={service.image} alt={service.title} className="service-image" />
                        <h2>{service.title}</h2>
                        <p>Ціна: {service.price} ₴</p>
                        <p>Длительность: {service.duration}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Services;