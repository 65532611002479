// Map.js
import React from 'react';
import './styles/Map.css';

const Map = () => {
    return (
        <section className="map-section">
            <h2>Як нас знайти</h2>
            <div className="map-container">
                <iframe
                    src="https://www.google.com/maps/embed?pb=ВАШ_EMBEDDED_MAP_LINK"
                    width="100%"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    title="Map"
                ></iframe>
            </div>
        </section>
    );
};

export default Map;
