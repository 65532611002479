// AboutUs.js
import React from 'react';
import './styles/AboutUS.css';

const AboutUs = () => {
    return (
        <section className="about-us">
            <h2>Про нас</h2>
            <p>
                Місія студії <strong>Krasunia_zp</strong> - зробити догляд за тілом і обличчям легким та приємним ритуалом 🙌
            </p>
            <p>
                Довіривши нам турботу про себе, ви робите правильний вибір! У нас працюють спеціалісти з медичною освітою, які дбайливо та якісно проведуть процедури лазерної епіляції, лазерного омолодження, електроепіляції, косметологічні процедури та пресотерапії.
            </p>
        </section>
    );
};

export default AboutUs;
